// Bootstrap
// You can add global styles to this file, and also import other style files
// For more details go to https://getbootstrap.com/docs/5.2/customize/sass/#importing

@import '../node_modules/bootstrap/scss/functions';
@import 'variables'; // overwrite variables values
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/utilities'; // add css utilities
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
@import '../node_modules/bootstrap/scss/helpers';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/accordion';
@import 'reset';

@import '~leaflet/dist/leaflet.css';

// animate css
@import '~animate.css/animate.min.css';

@media print {
  app-root > * {
    display: none;
  }

  app-root app-print-layout {
    display: block;
  }
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.body-loggedout {
  background: url('/assets/img/loginb.jpg') no-repeat center center fixed;
  background-size: cover;
}

.body-loggedout .mat-drawer-container {
  background-color: transparent !important;
}

table {
  width: 100%;
}

.mat-mdc-row:hover {
  background-color: rgba(0, 0, 0, 0.035) !important;
  cursor: pointer;
}

// Angular Material is using transform: translate3d(0,0,0); in components styles. Unfortunately transform changes
// positioning context and preview won't work properly. To avoid this situation you have to override material styles,
// for example:
// .mat-sidenav-container, .mat-sidenav-content, .mat-tab-body-content {
//   transform: none !important;
// }
.bg-img-200 {
  height: 200px;
  width: 200px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.bg-img-45 {
  height: 45px;
  width: 45px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.bg-img-35 {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.warning {
  color: #ffc107;
}

.danger {
  color: #c62828;
}

.accent {
  color: #afb42b;
}

.c-orange {
  color: #f35430 !important;
}

.c-dblue {
  color: #122640;
}

.c-green {
  color: #babf15;
}

.mat-tab-label.mat-tab-label-active {
  background-color: whitesmoke !important;
}

.bg-orange {
  background-color: #f35430 !important;
}

.bg-green-light {
  background-color: #28a74628 !important;
}

.bg-hover {
  background-color: rgba(0, 0, 0, 0.035) !important;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  line-height: 14px;
  min-width: auto;
}

.row-subtext {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5) !important;
}

.dialog-full .mat-dialog-container {
  padding: 0px;
  border-radius: 0px;
}

.btn-breadcrumb {
  padding: 2px !important;
  font-size: 12px;
  min-width: auto !important;
  line-height: unset !important;
}

.btn-breadcrumb:not(.mat-button-disabled) {
  text-decoration: underline;
}

.c-pointer {
  cursor: pointer;
}

.expansion-trabajadores .mat-expansion-panel-body {
  padding: 0px !important;
}

.bl-gray {
  border-left: 1px solid #efefef;
}

.pill {
  background-color: #f7f7f7;
  //margin: 10px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

div.scrollX {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  padding-bottom: 12px;
}

div.scrollX::-webkit-scrollbar {
  // display: none; // prevent show scroll x bar indicator
}

div.mat-chip-list-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .mat-chip {
    flex: 0 0 auto;
  }
}

div.mat-chip-list-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
div.mat-chip-list-wrapper::-webkit-scrollbar-track {
  background: rgb(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* Handle */
div.mat-chip-list-wrapper::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.lh-12 {
  line-height: 12px !important;
}

.icon-s-14 {
  font-size: 14px;
  height: 14px !important;
  width: 14px !important;
}

.bb-gray {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mat-expansion-no-padding .mat-expansion-panel-body {
  padding: 0;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-icon-18 {
  width: 18px !important;
  height: 18px !important;
  font-size: 16px;
}

.mat-form-field-sm {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-infix {
      min-height: 24px;
      padding-top: 14px;
      padding-bottom: 2px;
      font-size: 14px;
    }
    .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 23px !important;
    }
  }
  mat-label {
    font-size: 13px;
  }
}

.image-preview-dialog {
  /*height: 100vh;
  width: 100vw !important;
  max-width: none !important;*/
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100%;
  height: 100%;

  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      background-color: rgb(17, 26, 34);
      border-radius: 0px;
    }
  }
  .mat-mdc-dialog-content {
    height: 100%;
    padding: 0px;
    max-height: initial;
  }
}

.algolia-result em {
  color: #f35430 !important;
  // font-weight: bold;
  font-style: normal;
}

.input-file-upload {
  opacity: 0;
  z-index: -1;
  position: absolute;
}
