// $link-color: #3f51b5;
$link-color: #e07944;
$link-hover-color: currentColor;
$link-hover-decoration: none;

$grid-breakpoints: (
  xs: 0, // handset portrait (small, medium, large) | handset landscape (small)
  sm: 600px, // handset landscape (medium, large) | tablet portrait(small, large)
  md: 960px, //  tablet landscape (small, large)
  lg: 1280px, // laptops and desktops
  xl: 1600px // large desktops
);

$container-max-widths: (
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1600px
);